import { makeStyles } from '@material-ui/styles';
import { Controller, useFormContext } from 'react-hook-form';
import { FC, useRef, useState, useEffect, ChangeEvent } from 'react';

import { Box, Button, IconButton, Typography } from '@mui/material';

import Iconify from 'src/components/iconify/iconify';
import SvgColor from 'src/components/svg-color/svg-color';

import ImageViewer from '../image-viewer';

interface FileUploadParam {
  defaultValue: any;
  onChange: (file: any) => void;
  editable: boolean;
  name: string;
  error?: any;
}

// interface ImageModalProps {
//   open: boolean;
//   handleClose: () => void;
//   image: any;
// }

const FileUploader: FC<FileUploadParam> = ({ defaultValue, onChange, name, editable }) => {
  const { control } = useFormContext();
  const classes = styles();
  const [currentImage, setCurrentImage] = useState(defaultValue);
  const [open, setOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (defaultValue instanceof File) {
      const fileUrl = URL.createObjectURL(defaultValue);
      setCurrentImage(fileUrl);
    } else {
      setCurrentImage(defaultValue);
    }
  }, [defaultValue]);

  const handleButtonClick = () => {
    if (!editable) return;
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      const fileUrl = URL.createObjectURL(selectedFile);
      setCurrentImage(fileUrl);
      onChange(selectedFile);
      event.target.value = '';
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageClick = () => {
    setOpen(true);
  };

  const RenderEdit = () => (
    <IconButton onClick={handleButtonClick} className={classes.iconContainer}>
      <Iconify icon="solar:restart-bold" width={12} height={12} />
    </IconButton>
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error } }) => (
        <>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {currentImage ? (
              <Box>
                {editable ? <RenderEdit /> : null}
                <Box onClick={handleImageClick}>
                  <img src={currentImage} className={classes.imageContainer} alt="" />
                </Box>
              </Box>
            ) : (
              <Button
                variant="outlined"
                name={name}
                onClick={handleButtonClick}
                className={[classes.imageContainer, error && classes.error].join(' ')}
              >
                <SvgColor src="/assets/icons/app/gallery-icon.svg" />
              </Button>
            )}
            {error && (
              <Typography
                variant="caption"
                color="error"
                style={{ marginTop: '8px', textAlign: 'center' }}
              >
                Image is required
              </Typography>
            )}
          </Box>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <ImageViewer open={open} handleClose={handleClose} image={currentImage} />
        </>
      )}
    />
  );
};

const styles = makeStyles({
  imageContainer: { borderRadius: 10, height: 50, width: 55, objectFit: 'cover' },
  error: {
    borderColor: 'red',
  },
  iconContainer: {
    backgroundColor: 'white',
    position: 'absolute',
    right: -4,
    top: -5,
    borderColor: 'red',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 4,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imagePreview: {
    position: 'relative',
    maxHeight: '75vh',
    maxWidth: '50vw',
    backgroundColor: 'transparent',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  imageStyle: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
  },

  img: {
    outline: 'none',
    objectFit: 'cover', // Ensure image fits inside modal
    width: '100%',
    height: '100%',
  },
  });

export default FileUploader;
