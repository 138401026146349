import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Divider,
} from '@mui/material';

import { ColumnDefinition, TableWrapperProps } from 'src/interfaces';

interface GenericTableProps {
  rows: any[]; // Array of data rows
  columns: ColumnDefinition[]; // Array of column definitions
  totalRecords: number; // Total number of records
  page: number; // Current page number
  pageSize: number; // Rows per page
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void; // Pagination handler
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void; // Rows per page handler
}
const TableWrapper: React.FC<TableWrapperProps> = ({ children }) => (
  <TableContainer sx={{ overflowX: 'auto' }}>{children}</TableContainer>
);
const GenericTable: React.FC<GenericTableProps> = ({
  rows,
  columns,
  totalRecords,
  page,
  pageSize,
  onPageChange,
  onRowsPerPageChange,
}) => {
   // eslint-disable-next-line consistent-return
   if (!columns || !rows) {
    console.error('Columns or rows are undefined');
    return null; // Intentionally return null
  }

  return (
    <>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align="center">
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, index) => (
                <TableRow key={row.id || index}>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="center">
                      {column.render ? column.render(row, index) : row[column.id] ?? 'N/A'}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableWrapper>
      <Divider />
      <TablePagination
        component="div"
        count={totalRecords}
        page={page}
        rowsPerPage={pageSize}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  );
};

export default GenericTable;
